<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">葛</span>西環七通り店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >東西線葛西駅徒歩9分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >東京都江戸川区中葛西8丁目22-9</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1687331016264!6m8!1m7!1sZ_pjo8SwAwo2fT1AAbD6IA!2m2!1d35.65730622137353!2d139.872552636199!3f257.3294872980028!4f1.208477150135863!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        <v-card-text
                            >※Googleストリートビューの画像ではREVOISTの看板が表示されていない場合がありますが実際には店舗前に掲示されています。</v-card-text
                        >
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">葛西駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て右側の南口（バスロータリー）に進んでください。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            環七通りに向かってバスロータリーをまっすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">3</span>.
                            環七通り沿いに三菱UFJ銀行をまっすぐ進んでください。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            環七通りと清砂大橋通りの大きい交差点の信号を環七通り沿い（マツダ側）にまっすぐ進んでください。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            ファミリーマートやまいばすけっとを通り越して環七通りをまっすぐ進んでください。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            そのまま環七通りを進みますと右手にREVOIST葛西環七通り店があり、到着です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの6号店です！
葛西駅南口から環七通り沿いに徒歩9分のジムです！
南葛西の方にとっては、葛西駅よりも近く、パーソナルトレーニングを受けやすい場所になります！
現在、葛西にお住まいの多くのお客様に通っていただいております！
お客様に寄り添ったパ―ソナルトレーナーが、あなたの身体の悩みを解決するために、親身になって、より楽しいレッスンを提供します！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '葛西環七通り店',
            center: {
                lat: 35.657473679626605,
                lng: 139.87243634271255,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.657473679626605,
                        lng: 139.87243634271255,
                    },
                    title: '葛西環七通り店',
                },
            ],
            message: `パーソナルジム REVOISTの6号店です！<br />
葛西駅南口から環七通り沿いに徒歩9分のジムです！<br />
南葛西の方にとっては、葛西駅よりも近く、パーソナルトレーニングを受けやすい場所になります！<br />
現在、葛西にお住まいの多くのお客様に通っていただいております！<br />
お客様に寄り添ったパ―ソナルトレーナーが、あなたの身体の悩みを解決するために、親身になって、より楽しいレッスンを提供します！<br /><br />
体型の悩みの解消はもちろんのこと、ケガや病気にならない健康的な身体づくりや毎日が楽しいと思える心の健康も意識しながら、目標に向かって一緒にトレーニングをしていきましょう！<br />
田島工トレーナー：葛西環七通り店
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '葛西環七通り店',
                    disabled: true,
                    href: 'access-7',
                },
            ]
        },

        imageMv() {
            return 'static/pc_access_6/葛西看板-squashed.JPG'
        },
        accessA() {
            return 'static/pc_access_6/葛西1-squashed.JPG'
        },
        accessB() {
            return 'static/pc_access_6/葛西2-squashed.JPG'
        },
        accessC() {
            return 'static/pc_access_6/葛西3-squashed.JPG'
        },
        accessD() {
            return 'static/pc_access_6/葛西4-squashed.JPG'
        },
        accessE() {
            return 'static/pc_access_6/葛西5-squashed.JPG'
        },
        accessF() {
            return 'static/pc_access_6/葛西看板-squashed.JPG'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/KasaiKan7Dori.jpg'
                : 'static/landing/KasaiKan7Dori.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/葛西環七通り店田島工.jpg'
                : 'static/landing/trainer/葛西環七通り店田島工.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
